*,
:before,
:after {
  box-sizing: border-box;
}

.theme-root {
  font-family: var(--font);
  line-height: var(--default-line-height);
}

body.scroll-locked {
  overflow: hidden;
}

.button-reset {
  all: unset;

  &:focus,
  &:focus-visible {
    outline-offset: 1;
    outline: var(--outline-color) auto 1px;
  }
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: var(--title-line-height);
  font-weight: var(--font-weight-bold);
  margin-top: 0;
  margin-bottom: 1rem;
}

h3 {
  font-size: var(--font-size-lg);
}

p {
  margin-top: 0;
}

figure {
  margin: 0;
}

img,
svg {
  vertical-align: middle;
}

button {
  background-color: transparent;
  border: 1px solid transparent;
  line-height: var(--default-line-height);
  font-size: inherit;

  &:disabled {
    opacity: 0.7;
  }
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
textarea {
  margin: 0;
  font-family: inherit;
}

[role="button"] {
  cursor: pointer;
}

// Remove the inheritance of word-wrap in Safari.
//
// Details at https://github.com/twbs/bootstrap/issues/24990
select {
  word-wrap: normal;
}

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
button,
[type="button"], // 1
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; // 2
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  &:not(:disabled) {
    cursor: pointer;
  }
}

// Remove inner border and padding from Firefox.
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input,
textarea {
  font-size: inherit;
}

textarea {
  resize: vertical;
}

// Correct the cursor style of increment and decrement buttons in Chrome.
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

th {
  text-align: left;
}

hr {
  border-style: solid;
  border-color: var(--divider-color);
  border-width: 0;
  border-bottom-width: 1px;
}

code {
  font-size: 87.5%;
  word-wrap: break-word;
}

pre {
  font-size: 87.5%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

iframe {
  border: none;
}

.border-all {
  border: 1px solid var(--divider-color);
}
.border-top {
  border-top: 1px solid var(--divider-color);
}
.border-left {
  border-left: 1px solid var(--divider-color);
}
.border-right {
  border-right: 1px solid var(--divider-color);
}
.border-bottom {
  border-bottom: 1px solid var(--divider-color);
}
