.form-control,
.with-placeholder {
  &::placeholder {
    color: var(--placeholder-color);
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: var(--placeholder-color);
  }

  &::-ms-input-placeholder {
    color: var(--placeholder-color);
  }

  &:-moz-focusring {
    text-shadow: none;
  }
}

.invalid + .invalid-feedback {
  display: inherit;
}

.no-pointer {
  pointer-events: none;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
