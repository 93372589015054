.theme-root,
.tint-root {
  --table-header-color: var(--secondary-color);
  --table-header-divider-color: var(--divider-color);
  --table-divider-color: var(--divider-color);
  --table-mobile-divider-color: var(--divider-color);
  --table-hover-bg-color: var(--hover-bg-color);
  --table-empty-color: var(--tertiary-color);
  --table-expanded-row-bg-color: var(--secondary-bg-color);
}

.simple-table-wrapper {
  border: 1px solid var(--divider-color);
  border-radius: var(--border-radius);
  overflow: hidden;
}
