.theme-root {
  --font: -apple-system, BlinkMacSystemFont, Inter, Roboto, Segoe UI,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --mono-font: "SF Mono", menlo, monaco, consolas, "Courier New", Courier,
    monospace;

  --default-line-height: 1.5;
  --reduced-line-height: 1.3;
  --title-line-height: 1.2;

  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 600;

  --font-size-xxxl: 1.5rem;
  --font-size-xxl: 1.375rem;
  --font-size-xl: 1.25rem;
  --font-size-lg: 1.125rem;
  --font-size-md: 1rem;
  --font-size-sm: 0.875rem;
  --font-size-xs: 0.8125rem;
  --font-size-xxs: 0.75rem;

  --section-title-font-size: var(--font-size-xl);
  --section-subtitle-font-size: var(--font-size-md);

  --small-section-title-font-size: var(--font-size-lg);
  --small-section-subtitle-font-size: var(--font-size-sm);
}

@media (max-width: 450px) {
  .theme-root {
    --section-title-font-size: var(--font-size-lg);
    --section-subtitle-font-size: var(--font-size-sm);

    --small-section-title-font-size: var(--font-size-md);
    --small-section-subtitle-font-size: var(--font-size-xs);
  }
}

.text-primary {
  color: var(--primary-color);
}

.text-secondary {
  color: var(--secondary-color);
}
.text-secondary-alpha {
  color: var(--secondary-color-alpha);
}

.text-tertiary {
  color: var(--tertiary-color);
}
.text-tertiary-alpha {
  color: var(--tertiary-color-alpha);
}

.text-success {
  color: var(--success-color);
}

.text-warning {
  color: var(--warning-color);
}

.text-error {
  color: var(--error-color);
}

.fs-xxl {
  font-size: var(--font-size-xxl);
}
.fs-xl {
  font-size: var(--font-size-xl);
}
.fs-lg {
  font-size: var(--font-size-lg);
}
.fs-md {
  font-size: var(--font-size-md);
}
.fs-sm {
  font-size: var(--font-size-sm);
}
.fs-xs {
  font-size: var(--font-size-xs);
}
.fs-xxs {
  font-size: var(--font-size-xxs);
}

.reduced-line-height {
  line-height: var(--reduced-line-height);
}

.line-height-1 {
  line-height: 1;
}

.text-uppercase {
  text-transform: uppercase;
}

b,
.b,
strong {
  font-weight: var(--font-weight-bold);
}

.text-ellipses {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.nowrap {
  white-space: nowrap;
}

.pre-line {
  white-space: pre-line;
}

.whitespace-pre {
  white-space: pre;
}

.fw-regular {
  font-weight: var(--font-weight-regular);
}

.fw-medium {
  font-weight: var(--font-weight-medium);
}

.fw-bold {
  font-weight: var(--font-weight-bold);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

// Uses the same font but gives you monospace numbers
.mono-number {
  font-variant-numeric: tabular-nums;
}

// Useful for text that could be transcribed character by character, but does not require monospacing, for example
// coupons. This adds markers like a line through a 0 and the curly part of an "l".
.high-legibility {
  font-feature-settings: "ss06" on;
}

.text-datetime {
  font-feature-settings: "ss01" on, "ss02" on;
}

.text-cranberry {
  color: var(--cranberry);
}
.text-white {
  color: var(--white);
}
.text-gray {
  color: var(--gray);
}
.text-barney {
  color: var(--barney);
}
.text-red {
  color: var(--red);
}
.text-calendar-red {
  color: var(--calendar-red);
}
.text-green {
  color: var(--green);
}
.text-blue {
  color: var(--blue);
}
.text-purple {
  color: var(--purple);
}
.text-yellow {
  color: var(--yellow);
}
.text-orange {
  color: var(--orange);
}
.text-zoom {
  color: var(--zoom-active-fg-color);
}

.lux-line-clamp {
  display: -webkit-box;

  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
