.section-title-wrapper {
  .section-title-row {
    margin-bottom: 1.25rem;
  }

  &.small .section-title-row {
    margin-bottom: 1rem;
  }

  h2 {
    font-size: var(--section-title-font-size);
    font-weight: var(--font-weight-bold);
    color: var(--primary-color);
    margin-bottom: 0;
  }

  &.small h2 {
    font-size: var(--small-section-title-font-size);
  }

  .right-element {
    margin: -0.25rem 0;
  }

  .section-subtitle {
    margin-top: -0.875rem;
    margin-bottom: 1.25rem;
    color: var(--secondary-color);
    font-size: var(--section-subtitle-font-size);
  }

  &.small .section-subtitle {
    font-size: var(--small-section-subtitle-font-size);
  }

  &.small .section-subtitle {
    margin-top: -0.5rem;
    margin-bottom: 1rem;
  }
}

.can-divide + .with-divider {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid var(--divider-color);

  @media (max-width: 450px) {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
  }
}

.can-divide.small + .with-divider.small {
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  border-top: 1px solid var(--divider-color);
}
