.theme-root,
.tint-root {
  --shimmer-color: var(--pale-gray);
  --shimmer-gradient: linear-gradient(
    to right,
    var(--pale-gray) 8%,
    var(--faint-gray) 18%,
    var(--pale-gray) 33%
  );
}

// Shimmer is the Facebook-like placeholder animation for loading.
@keyframes shimmer {
  0% {
    background-position: -568px 0;
  }
  100% {
    background-position: 568px 0;
  }
}

.text-shimmer-wrapper,
.shimmer-wrapper {
  position: relative;
}

.shimmer {
  animation-duration: 1.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: var(--shimmer-color);
  background: var(--shimmer-gradient);
  background-size: 1200px 104px;
  height: 1rem;
  margin-bottom: 0.5rem;
  position: relative;
}

.shimmer-round {
  border-radius: var(--border-radius);
}

.shimmer-circle {
  border-radius: 100%;
}

.text-shimmer-wrapper {
  // The percentage of the height the text should take up (compared to padding)
  --text-shimmer-text-ratio: 0.625;
  --text-shimmer-padding-ratio: calc(1 - var(--text-shimmer-text-ratio));
  --text-shimmer-padding-vertical: calc(
    var(--text-shimmer-height) * var(--text-shimmer-padding-ratio) / 2
  );

  padding-top: var(--text-shimmer-padding-vertical);
  padding-bottom: var(--text-shimmer-padding-vertical);

  .shimmer {
    height: calc(var(--text-shimmer-height) * var(--text-shimmer-text-ratio));
    margin-bottom: 0;
    margin-top: 0;
  }
}
