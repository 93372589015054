.btn {
  font-weight: var(--font-weight-medium);
  border-radius: var(--border-radius);
}

.btn-sm.with-svg {
  svg {
    width: var(--font-size-sm);
    height: var(--font-size-sm);
  }

  &.svg-left {
    svg {
      margin-right: 0.25rem;
    }
  }

  &.svg-right {
    svg {
      margin-left: 0.25rem;
    }
  }
}

.btn-a,
.btn-link {
  color: var(--brand-color);
  cursor: pointer;

  &:focus,
  &:active,
  &:hover {
    text-decoration: none;
    color: var(--brand-active-color);
  }
}

.btn-a {
  font-size: inherit;
  vertical-align: inherit;
  font-weight: inherit;
  line-height: inherit;
  border: 0;
  padding: 0;
  display: inline-block;
}

.btn-link-gray {
  color: var(--tertiary-color);

  &:hover {
    color: var(--secondary-color);
  }
}
