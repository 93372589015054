.theme-root,
.tint-root {
  --rich-text-toolbar-button-color: var(--tertiary-color);
  --rich-text-toolbar-button-hover-color: var(--primary-color);
  --rich-text-toolbar-button-hover-bg-color: var(--hover-bg-color);
  --rich-text-toolbar-button-active-color: var(--brand-color);
  --rich-text-toolbar-button-active-hover-bg-color: var(--hover-bg-color);

  --editor-selected-text-bg-color: var(--brand-pale-bg-color);
  --editor-selected-node-border-color: var(--brand-pale-bg-color);
  --editor-resize-control-color: var(--brand-color);
}
