.simple-drop-zone-container {
  position: relative;
  width: max-content;
  max-width: 100%;

  .drop-zone {
    height: var(--size);
    width: var(--size);
    max-width: 100%;
    background-color: var(--tertiary-bg-color);
    cursor: pointer;
    transition: var(--transition);
    background-size: cover;
  }

  .drop-zone.square {
    border-radius: var(--border-radius);
  }

  .drop-zone.rectangle,
  .drop-zone.wide-rectangle {
    border-radius: var(--border-radius);
  }

  .drop-zone.rectangle {
    width: calc(var(--size) * 2);
  }

  .drop-zone.wide-rectangle {
    width: calc(var(--size) * 5);
  }

  .drop-zone.circle {
    border-radius: 999px;
  }

  .drop-zone.active {
    background-color: var(--success-color);
  }

  .drop-zone.contain {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .image-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: var(--opacity-16);

    svg {
      width: calc(var(--size) * 0.5);
      height: calc(var(--size) * 0.5);
    }
  }

  .has-image .image-icon {
    display: none;
  }

  .icon {
    position: absolute;
    bottom: -2px;
    right: -2px;
    height: 2rem;
    width: 2rem;
    border-radius: var(--border-radius);
    background-color: var(--primary-color);
    border: 2px solid var(--primary-bg-color);
    display: flex;
    place-items: center;
    transition: var(--transition);
    color: var(--primary-bg-color);

    svg {
      margin: 0 auto;
      stroke-width: 3;
    }
  }

  .drop-zone:hover .icon,
  .drop-zone.active .icon {
    background-color: var(--brand-color);
    color: white;
  }

  .drop-zone.active.has-image .icon {
    background-color: var(--success-color);
  }

  .remove-button {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;

    .luma-button {
      --padding: 0.1rem;
      --height: 1.2rem;

      svg {
        stroke-width: 3;
      }
    }
  }
}
