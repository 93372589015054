.theme-root.light {
  --brand-color: var(--barney-50);
  --brand-active-color: var(--barney-40);
  --brand-pale-bg-color: var(--barney-10);
  --primary-bg-color-translucent: var(--white-thick-translucent);
}

.theme-root.dark {
  --brand-color: var(--barney-40);
  --brand-active-color: var(--barney-50);
  --primary-bg-color-translucent: var(--black-thick-translucent);
}

body {
  --max-width: 1000px;
}
