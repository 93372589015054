.theme-root {
  --tiny-pill-padding: 0.25rem 0.4375rem;
  --tiny-pill-delete-margin: -0.25rem -0.5rem -0.25rem -0.1875rem;
  --tiny-pill-font-size: 0.75rem;
  --tiny-pill-element-gap: 0.1875rem;

  --small-pill-padding: 0.3125rem 0.5625rem;
  --small-pill-delete-margin: -0.3125rem -0.625rem -0.3125rem -0.25rem;
  --small-pill-font-size: 0.875rem;
  --small-pill-element-gap: 0.25rem;

  --medium-pill-padding: 0.4375rem 0.75rem;
  --medium-pill-delete-margin: -0.4375rem -0.875rem -0.4375rem -0.5rem;
  --medium-pill-font-size: 1rem;
  --medium-pill-element-gap: 0.3125rem;
}
