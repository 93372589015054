.lux-radio {
  position: relative;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.25rem;
  clear: both;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  &.radio-right {
    flex-direction: row-reverse;
  }

  .text-label {
    flex: 1 1;
  }

  &:has(input:focus-visible) .radio-icon {
    outline: 2px solid var(--brand-color);
    outline-offset: 2px;
    border-radius: 100%;
  }

  .radio-icon {
    display: block;
    position: relative;
    font-size: 0.5rem;
    line-height: 1.25rem;
    height: 1.25rem;
    width: 1.25rem;
    clear: both;

    .input {
      opacity: 0;
    }

    .input,
    .radio-display {
      position: absolute;
      top: 0;
      left: 0;
      height: 1.25rem;
      width: 1.25rem;
    }

    .radio-display {
      transition: var(--fast-transition);
      border-radius: 100px;
      background-color: var(--primary-bg-color);
      border: 0.125rem solid var(--checkbox-border-color);
    }

    .radio-display::after {
      transition: var(--fast-transition);
      position: absolute;
      content: "";
      top: 0.375rem;
      left: 0.375rem;
      height: 0.5rem;
      width: 0.5rem;
      opacity: 0;
    }

    input:checked ~ .radio-display {
      border: none;
      background-color: var(--brand-color);
    }

    input:checked ~ .radio-display::after {
      opacity: 1;
      border-radius: 100px;
      background-color: var(--white);
    }
  }

  &:hover .radio-display {
    border-color: var(--brand-color);
  }
}
