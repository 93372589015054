.lux-naked-input {
  transition: var(--transition), height 0s;
  color: var(--input-color);
  line-height: var(--naked-input-line-height);
  overflow: hidden;
  border: none;
  background-color: transparent;
  padding: var(--naked-input-padding);
  font-size: var(--input-font-size);
  font-weight: var(--naked-input-font-weight);
  width: 100%;

  &:not(.mounted) {
    height: var(--naked-input-guidance-height);
  }

  &.padded {
    --naked-input-padding: var(--input-padding);

    border-radius: var(--border-radius);

    &:not(:disabled):hover {
      background-color: var(--naked-input-hover-bg-color);
    }
  }

  &.bordered {
    --naked-input-padding: var(--input-vertical-padding) 0;

    border-bottom: 1px solid var(--input-border-color);

    &:not(:disabled):hover {
      border-color: var(--input-hover-border-color);
    }

    &:focus, &:focus:hover {
      border-color: var(--input-focus-border-color);
    }
  }

  &.invalid {
    color: var(--error-color);

    &.bordered {
      border-color: var(--error-color);
    }

    &::placeholder {
      color: var(--error-placeholder-color) !important;
    }
  }

  // Fixes mobile Safari shadow
  background-image: -webkit-gradient(
    linear,
    0% 0%,
    0% 100%,
    from(hsla(0, 0%, 100%, 0)),
    to(hsla(0, 0%, 100%, 0))
  );
  background-image: -webkit-linear-gradient(
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0)
  );

  &:disabled {
    cursor: not-allowed;
  }

  &:focus,
  &:focus:hover {
    outline: 0;
  }

  &::placeholder {
    color: var(--placeholder-color);
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: var(--placeholder-color);
  }

  &::-ms-input-placeholder {
    color: var(--placeholder-color);
  }

  &:not(.padded):not(.bordered) {
    &::placeholder {
      transition: color var(--transition-duration) var(--transition-fn);
    }

    &:hover::placeholder, &:focus::placeholder {
      color: var(--naked-input-hover-placeholder-color);
    }
  }

  &:-moz-focusring {
    text-shadow: none;
  }
}
