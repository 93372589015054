/**
 * Prism themes copied from:
 * https://unpkg.com/prism-themes@1.9.0/themes/prism-one-light.css
 * https://unpkg.com/prism-themes@1.9.0/themes/prism-one-dark.css
 */

.lux-code-block {
  --code-block-bg-color: var(--elevated-primary-bg-color);
  --code-block-selection-bg-color: var(--opacity-8);
  --code-block-color: var(--primary-color);
}

.theme-root .lux-code-block {
  code[class*="language-"],
  pre[class*="language-"] {
    background: var(--code-block-bg-color);
    color: var(--code-block-color);
    font-family: var(--mono-font);
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    line-height: 1.5;
    hyphens: none;
  }

  pre[class*="language-"] {
    padding: 0.75rem 1rem;
    margin: 0;
    overflow: auto;
    border-radius: var(--border-radius);
  }
  :not(pre) > code[class*="language-"] {
    padding: 0.2rem 0.3rem;
    border-radius: 0.3rem;
    white-space: normal;
  }
  .token.cdata,
  .token.comment,
  .token.prolog {
    color: #9fa0a6;
  }
  .token.doctype,
  .token.entity,
  .token.punctuation {
    color: #383942;
  }
  .token.atrule,
  .token.attr-name,
  .token.boolean,
  .token.class-name,
  .token.constant,
  .token.number {
    color: #b66a00;
  }
  .token.keyword {
    color: #a625a4;
  }
  .token.deleted,
  .token.important,
  .token.property,
  .token.symbol,
  .token.tag {
    color: #e35549;
  }
  .token.attr-value,
  .token.attr-value > .token.punctuation,
  .token.builtin,
  .token.char,
  .token.inserted,
  .token.regex,
  .token.selector,
  .token.string {
    color: #50a04f;
  }
  .token.function,
  .token.operator,
  .token.variable {
    color: #4078f1;
  }
  .token.url {
    color: #0083bb;
  }
  .token.attr-value > .token.punctuation.attr-equals,
  .token.special-attr > .token.attr-value > .token.value.css {
    color: #383942;
  }
  .language-css .token.selector {
    color: #e35549;
  }
  .language-css .token.property {
    color: #383942;
  }
  .language-css .token.function,
  .language-css .token.url > .token.function {
    color: #0083bb;
  }
  .language-css .token.url > .token.string.url {
    color: #50a04f;
  }
  .language-css .token.atrule .token.rule,
  .language-css .token.important {
    color: #a625a4;
  }
  .language-javascript .token.operator {
    color: #a625a4;
  }
  .language-javascript
    .token.template-string
    > .token.interpolation
    > .token.interpolation-punctuation.punctuation {
    color: #c91142;
  }
  .language-json .token.operator {
    color: #383942;
  }
  .language-json .token.null.keyword {
    color: #b66a00;
  }
  .language-markdown .token.url,
  .language-markdown .token.url-reference.url > .token.string,
  .language-markdown .token.url > .token.operator {
    color: #383942;
  }
  .language-markdown .token.url > .token.content {
    color: #4078f1;
  }
  .language-markdown .token.url-reference.url,
  .language-markdown .token.url > .token.url {
    color: #0083bb;
  }
  .language-markdown .token.blockquote.punctuation,
  .language-markdown .token.hr.punctuation {
    color: #9fa0a6;
    font-style: italic;
  }
  .language-markdown .token.code-snippet {
    color: #50a04f;
  }
  .language-markdown .token.bold .token.content {
    color: #b66a00;
  }
  .language-markdown .token.italic .token.content {
    color: #a625a4;
  }
  .language-markdown .token.list.punctuation,
  .language-markdown .token.strike .token.content,
  .language-markdown .token.strike .token.punctuation,
  .language-markdown .token.title.important > .token.punctuation {
    color: #e35549;
  }
  .token.bold {
    font-weight: 700;
  }
  .token.comment,
  .token.italic {
    font-style: italic;
  }
  .token.entity {
    cursor: help;
  }
  .token.namespace {
    opacity: 0.8;
  }
  .token.token.cr:before,
  .token.token.lf:before,
  .token.token.space:before,
  .token.token.tab:not(:empty):before {
    color: hsla(230, 8%, 24%, 0.2);
  }
  .line-highlight.line-highlight {
    background: hsla(230, 8%, 24%, 0.05);
  }
  .line-highlight.line-highlight:before,
  .line-highlight.line-highlight[data-end]:after {
    background: #e5e5e5;
    color: #383942;
    padding: 0.1rem 0.6rem;
    border-radius: 0.3rem;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
  }
  .rainbow-braces .token.token.punctuation.brace-level-1,
  .rainbow-braces .token.token.punctuation.brace-level-5,
  .rainbow-braces .token.token.punctuation.brace-level-9 {
    color: #e35549;
  }
  .rainbow-braces .token.token.punctuation.brace-level-10,
  .rainbow-braces .token.token.punctuation.brace-level-2,
  .rainbow-braces .token.token.punctuation.brace-level-6 {
    color: #50a04f;
  }
  .rainbow-braces .token.token.punctuation.brace-level-11,
  .rainbow-braces .token.token.punctuation.brace-level-3,
  .rainbow-braces .token.token.punctuation.brace-level-7 {
    color: #4078f1;
  }
  .rainbow-braces .token.token.punctuation.brace-level-12,
  .rainbow-braces .token.token.punctuation.brace-level-4,
  .rainbow-braces .token.token.punctuation.brace-level-8 {
    color: #a625a4;
  }
  .prism-previewer-gradient.prism-previewer-gradient div,
  .prism-previewer.prism-previewer:before {
    border-color: #f2f2f2;
  }
  .prism-previewer-color.prism-previewer-color:before,
  .prism-previewer-easing.prism-previewer-easing:before,
  .prism-previewer-gradient.prism-previewer-gradient div {
    border-radius: 0.3rem;
  }
  .prism-previewer.prism-previewer:after {
    border-top-color: #f2f2f2;
  }
  .prism-previewer-flipped.prism-previewer-flipped.after {
    border-bottom-color: #f2f2f2;
  }
  .prism-previewer-angle.prism-previewer-angle:before,
  .prism-previewer-easing.prism-previewer-easing,
  .prism-previewer-time.prism-previewer-time:before {
    background: #fff;
  }
  .prism-previewer-angle.prism-previewer-angle circle,
  .prism-previewer-time.prism-previewer-time circle {
    stroke: #383942;
    stroke-opacity: 1;
  }
  .prism-previewer-easing.prism-previewer-easing circle,
  .prism-previewer-easing.prism-previewer-easing line,
  .prism-previewer-easing.prism-previewer-easing path {
    stroke: #383942;
  }
  .prism-previewer-easing.prism-previewer-easing circle {
    fill: transparent;
  }
}

.theme-root.dark .lux-code-block {
  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: 0 1px rgba(0, 0, 0, 0.3);
  }

  .token.cdata,
  .token.comment,
  .token.prolog {
    color: #5b6270;
  }
  .token.doctype,
  .token.entity,
  .token.punctuation {
    color: #aab1bf;
  }
  .token.atrule,
  .token.attr-name,
  .token.boolean,
  .token.class-name,
  .token.constant,
  .token.number {
    color: #d19965;
  }
  .token.keyword {
    color: #c578dd;
  }
  .token.deleted,
  .token.important,
  .token.property,
  .token.symbol,
  .token.tag {
    color: #df6b75;
  }
  .token.attr-value,
  .token.attr-value > .token.punctuation,
  .token.builtin,
  .token.char,
  .token.inserted,
  .token.regex,
  .token.selector,
  .token.string {
    color: #97c279;
  }
  .token.function,
  .token.operator,
  .token.variable {
    color: #61afef;
  }
  .token.url {
    color: #56b5c2;
  }
  .token.attr-value > .token.punctuation.attr-equals,
  .token.special-attr > .token.attr-value > .token.value.css {
    color: #aab1bf;
  }
  .language-css .token.selector {
    color: #df6b75;
  }
  .language-css .token.property {
    color: #aab1bf;
  }
  .language-css .token.function,
  .language-css .token.url > .token.function {
    color: #56b5c2;
  }
  .language-css .token.url > .token.string.url {
    color: #97c279;
  }
  .language-css .token.atrule .token.rule,
  .language-css .token.important {
    color: #c578dd;
  }
  .language-javascript .token.operator {
    color: #c578dd;
  }
  .language-javascript
    .token.template-string
    > .token.interpolation
    > .token.interpolation-punctuation.punctuation {
    color: #be5046;
  }
  .language-json .token.operator {
    color: #aab1bf;
  }
  .language-json .token.null.keyword {
    color: #d19965;
  }
  .language-markdown .token.url,
  .language-markdown .token.url-reference.url > .token.string,
  .language-markdown .token.url > .token.operator {
    color: #aab1bf;
  }
  .language-markdown .token.url > .token.content {
    color: #61afef;
  }
  .language-markdown .token.url-reference.url,
  .language-markdown .token.url > .token.url {
    color: #56b5c2;
  }
  .language-markdown .token.blockquote.punctuation,
  .language-markdown .token.hr.punctuation {
    color: #5b6270;
  }
  .language-markdown .token.code-snippet {
    color: #97c279;
  }
  .language-markdown .token.bold .token.content {
    color: #d19965;
  }
  .language-markdown .token.italic .token.content {
    color: #c578dd;
  }
  .language-markdown .token.list.punctuation,
  .language-markdown .token.strike .token.content,
  .language-markdown .token.strike .token.punctuation,
  .language-markdown .token.title.important > .token.punctuation {
    color: #df6b75;
  }
  .token.token.cr:before,
  .token.token.lf:before,
  .token.token.space:before,
  .token.token.tab:not(:empty):before {
    color: hsla(220, 14%, 71%, 0.15);
  }
  .line-highlight.line-highlight {
    background: hsla(220, 100%, 80%, 0.04);
  }
  .line-highlight.line-highlight:before,
  .line-highlight.line-highlight[data-end]:after {
    background: #393f4a;
    color: #aab1bf;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
  }
  .rainbow-braces .token.token.punctuation.brace-level-1,
  .rainbow-braces .token.token.punctuation.brace-level-5,
  .rainbow-braces .token.token.punctuation.brace-level-9 {
    color: #df6b75;
  }
  .rainbow-braces .token.token.punctuation.brace-level-10,
  .rainbow-braces .token.token.punctuation.brace-level-2,
  .rainbow-braces .token.token.punctuation.brace-level-6 {
    color: #97c279;
  }
  .rainbow-braces .token.token.punctuation.brace-level-11,
  .rainbow-braces .token.token.punctuation.brace-level-3,
  .rainbow-braces .token.token.punctuation.brace-level-7 {
    color: #61afef;
  }
  .rainbow-braces .token.token.punctuation.brace-level-12,
  .rainbow-braces .token.token.punctuation.brace-level-4,
  .rainbow-braces .token.token.punctuation.brace-level-8 {
    color: #c578dd;
  }
  .prism-previewer-gradient.prism-previewer-gradient div,
  .prism-previewer.prism-previewer:before {
    border-color: #252830;
  }
  .prism-previewer.prism-previewer:after {
    border-top-color: #252830;
  }
  .prism-previewer-flipped.prism-previewer-flipped.after {
    border-bottom-color: #252830;
  }
  .prism-previewer-angle.prism-previewer-angle:before,
  .prism-previewer-easing.prism-previewer-easing,
  .prism-previewer-time.prism-previewer-time:before {
    background: #30353f;
  }
  .prism-previewer-angle.prism-previewer-angle circle,
  .prism-previewer-time.prism-previewer-time circle {
    stroke: #aab1bf;
    stroke-opacity: 1;
  }
  .prism-previewer-easing.prism-previewer-easing circle,
  .prism-previewer-easing.prism-previewer-easing line,
  .prism-previewer-easing.prism-previewer-easing path {
    stroke: #aab1bf;
  }
}
