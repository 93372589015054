body {
  --max-width: 820px;
  --max-width-wide-page: 960px;
  --max-width-extra-wide-page: 1080px;
  --horizontal-padding: 1rem;

  --small-border-radius: 0.25rem;
  --border-radius: 0.5rem;
  --large-border-radius: 1rem;
  --modal-border-radius: var(--large-border-radius);

  --card-border-radius: 0.75rem;
  --content-card-vertical-padding: 1rem;
  --content-card-horizontal-padding: 1.125rem;

  --chat-z-index: 900;
  --overlay-z-index: 1000;
}
