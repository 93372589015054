.lux-empty-state {
  text-align: center;
  margin-top: 4rem;

  &.large {
    h3 {
      font-size: var(--font-size-xxxl);
      font-weight: var(--font-weight-medium);

      @media (max-width: 450px) {
        font-size: var(--font-size-lg);
        font-weight: var(--font-weight-bold);
      }
    }
  }

  .icon {
    &.regular {
      svg {
        width: 4rem;
        height: 4rem;
        color: var(--tertiary-color-alpha);
      }
    }

    &.illustration {
      svg {
        width: 250px;
        height: auto;
        shape-rendering: crispEdges;
      }

      @media (max-width: 450px) {
        svg {
          width: 200px;
        }
      }
    }

    &.round {
      width: 4rem;
      height: 4rem;
      background-color: var(--secondary-bg-color);
      border-radius: 50%;
      overflow: hidden;

      svg {
        width: 2rem;
        height: 2rem;
        color: var(--quaternary-bg-color);
      }
    }
  }

  .desc {
    padding-left: 3rem;
    padding-right: 3rem;

    @media (max-width: 650px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  img {
    max-width: 250px;
  }

  @media (max-width: 450px) {
    img {
      max-width: 200px;
    }
  }
}
