.theme-root {
  --ig-gradient: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  --tiktok-gradient: linear-gradient(45deg, #68c9d0 0%, #ef1c51 100%);

  --discord-fg-color: #5865f2;
  --discord-bg-color: #5865f2;
  --discord-active-fg-color: #2738f3;
  --discord-active-bg-color: #2738f3;
  --discord-contrast-color: white;

  --ethereum-fg-color: #954fff;
  --ethereum-bg-color: #954fff;
  --ethereum-active-fg-color: #6600ff;
  --ethereum-active-bg-color: #6600ff;
  --ethereum-contrast-color: white;

  --glow-fg-color: #de3163;
  --glow-bg-color: #de3163;
  --glow-active-fg-color: #c22b57;
  --glow-active-bg-color: #c22b57;
  --glow-contrast-color: white;

  --google-fg-color: #4285f4;
  --google-bg-color: #4285f4;
  --google-active-fg-color: #0d6aff;
  --google-active-bg-color: #0d6aff;
  --google-contrast-color: white;

  --slack-fg-color: #7a2d83;
  --slack-bg-color: #7a2d83;
  --slack-active-fg-color: #650070;
  --slack-active-bg-color: #650070;
  --slack-contrast-color: white;

  --twitter-fg-color: #1da1f2;
  --twitter-bg-color: #1da1f2;
  --twitter-active-fg-color: #0088dd;
  --twitter-active-bg-color: #0088dd;
  --twitter-contrast-color: white;

  --solana-fg-color: #00c070;
  --solana-bg-color: #00c070;
  --solana-active-fg-color: #00814b;
  --solana-active-bg-color: #00814b;
  --solana-contrast-color: white;

  --youtube-fg-color: #ff0000;
  --youtube-bg-color: #ff0000;
  --youtube-active-fg-color: #be0000;
  --youtube-active-bg-color: #be0000;
  --youtube-contrast-color: white;

  --zoom-fg-color: #2d8cff;
  --zoom-bg-color: #2d8cff;
  --zoom-active-fg-color: #006cf0;
  --zoom-active-bg-color: #006cf0;
  --zoom-contrast-color: white;

  --microsoft-fg-color: #00a4ef;
  --microsoft-bg-color: #00a4ef;
  --microsoft-active-fg-color: #0083bf;
  --microsoft-active-bg-color: #0083bf;
  --microsoft-contrast-color: white;
}

.theme-root.dark {
  --discord-fg-color: #7883fd;
  --discord-active-fg-color: #98a0ff;
  --ethereum-fg-color: #a66bff;
  --ethereum-active-fg-color: #b583ff;
  --glow-fg-color: #e66288;
  --glow-active-fg-color: #ee98b1;
  --google-fg-color: #6ca4ff;
  --google-active-fg-color: rgb(169, 201, 255);
  --slack-fg-color: #00de82;
  --slack-active-fg-color: #14fe9d;
  --twitter-fg-color: #1da1f2;
  --twitter-active-fg-color: #4ebbff;
  --youtube-fg-color: #ff3a3a;
  --youtube-active-fg-color: #ff6666;
  --zoom-fg-color: #4d9dff;
  --zoom-active-fg-color: #6aadff;
  --microsoft-fg-color: #1ab7ff;
  --microsoft-active-fg-color: #33bfff;
}
