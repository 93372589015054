.mobile-only {
  display: none;

  @media (max-width: 650px) {
    display: block;
  }
}

.desktop-only {
  @media (max-width: 650px) {
    display: none;
  }
}

.inverted-filter {
  filter: invert(0.95) hue-rotate(180deg);
}

.theme-root.dark .adaptive-inverted-filter {
  filter: invert(0.95) hue-rotate(180deg);
}

.theme-root.dark .tint-root.light .adaptive-inverted-filter {
  filter: none;
}
