// Flexbox.

.flex {
  display: flex;
}

.flex-baseline {
  display: flex;
  align-items: baseline;
}

.flex-baseline-center {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-start {
  display: flex;
  align-items: flex-start;
}

.flex-end {
  display: flex;
  align-items: flex-end;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.flex-1 {
  flex: 1;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-start {
  display: flex;
  justify-content: flex-start;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.align-items-flex-end {
  display: flex;
  align-items: flex-end;
}

.align-self-center {
  align-self: center;
}

.spread {
  display: flex;
  justify-content: space-between;
}

.stretch {
  align-items: stretch;
}

.space-around {
  display: flex;
  justify-content: space-around;
}

// Utilities.

.full-width {
  width: 100%;
}

.full-height {
  min-height: 100%;
}

.min-width-0 {
  min-width: 0;
}

.force-full-height {
  height: 100%;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.flex-inline {
  display: inline-flex;
}

// Positioning

.relative {
  position: relative;
}

// Bootstrap margin / padding.

$spacers: (
  0: 0,
  1: 0.25rem,
  2: 0.5rem,
  3: 1rem,
  4: 1.5rem,
  5: 3rem,
);

// For each of the spacers and margin / padding we create different classes
// m-1, mb-1, mt-1, mx-1, my-1, ml-1, mr-1
// For both margin and padding and num from 0 to 5
@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }
    .#{$abbrev}t-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length !important;
    }
    .#{$abbrev}r-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length !important;
    }
    .#{$abbrev}b-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-bottom: $length !important;
    }
    .#{$abbrev}l-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}

@each $prop, $abbrev in (column-gap: cgap, row-gap: rgap, gap: gap) {
  @each $size, $length in $spacers {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length;
    }
  }
}
