.dropzone-wrapper {
  height: 100%;
  width: 100%;

  position: relative;
  padding: 1.5rem;
  background-color: var(--faint-gray);
  border-radius: var(--border-radius);
  border: 1px dashed var(--pale-gray);
  cursor: pointer;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    background-color: var(--pale-gray);
  }

  &.dragging,
  &.active {
    svg,
    .label,
    .sublabel {
      color: white;
    }
  }

  &.dragging {
    background: var(--success-color);
  }

  &.active {
    background: var(--warning-color);
  }

  &.loading {
    animation: pulse 1s ease infinite alternate;
  }

  svg {
    width: 2rem;
    height: 2rem;
    transition: var(--transition);
    color: var(--secondary-color-alpha);
  }

  svg + .label {
    margin-top: 1rem;
  }

  @keyframes pulse {
    from {
      opacity: 0.8;
    }
    to {
      opacity: 1;
    }
  }
}
