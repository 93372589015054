.lux-menu-trigger-wrapper {
  display: inline-flex;
  min-width: 0;
}

.lux-menu {
  --lux-menu-horizontal-padding: 0.25rem;

  position: relative;
  border-radius: var(--border-radius);
  border: 1px solid var(--menu-border-color);
  max-height: inherit;
  background-color: var(--menu-bg-color);
}

.lux-menu-divider {
  height: 1px;
  background-color: var(--divider-color);
  margin: 0.25rem calc(-1 * var(--lux-menu-horizontal-padding));
  width: calc(100% + 2 * var(--lux-menu-horizontal-padding));
}

.lux-menu-arrow {
  position: absolute;
  clip-path: polygon(100% 0, 0 0, 50% 100%);
  width: 10px;
  height: 5px;
  background-color: var(--menu-bg-color);
  backdrop-filter: blur(16px);

  &:not(.flipped) {
    transform: rotate(180deg);
  }
}

.lux-menu-wrapper {
  z-index: 1001;
  border-radius: var(--border-radius);
  backdrop-filter: blur(16px);
}

@media (hover: none) {
  .lux-menu-wrapper.hover {
    display: none;
  }
}

.lux-menu-wrapper.inverted {
  --menu-bg-color: var(--menu-inverted-bg-color);
  --menu-border-color: var(--menu-inverted-border-color);
}

.lux-menu-content {
  min-width: 120px;
  max-width: 260px;
}

.lux-menu-search-wrapper {
  position: sticky;
  top: 0;
  background-color: var(--menu-search-bg-color);
  border-bottom: 1px solid var(--menu-search-divider-color);
  z-index: 2;
  backdrop-filter: blur(16px);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);

  &:last-child {
    border-bottom-color: var(--opacity-0);
  }

  input {
    background-color: transparent;
    border: 0;
    padding: 0.375rem 0.75rem;
    outline: 0;
    width: 100%;
    color: var(--input-color);
  }
}

.lux-menu-content {
  .rows {
    padding: 0.25rem var(--lux-menu-horizontal-padding);
  }

  .create-row,
  .no-result {
    font-size: var(--font-size-sm);
    padding: 0.375rem 0.5rem;
    border-radius: var(--small-border-radius);
  }

  .no-result {
    color: var(--menu-no-result-color);
  }

  .create-row {
    cursor: pointer;

    .icon {
      margin-right: 0.75rem;
      width: 1rem;
      height: 1rem;
      color: var(--menu-item-icon-color);

      :global(svg) {
        width: 1rem;
        height: 1rem;
      }
    }

    span {
      font-weight: var(--font-weight-medium);
    }

    &.selected {
      color: var(--menu-item-hover-color);
      background-color: var(--menu-item-hover-bg-color);

      .icon {
        color: var(--menu-item-icon-hover-color);
      }
    }
  }
}

.lux-menu-header {
  padding: 0.25rem 0.5rem;
  color: var(--menu-header-color);
  font-size: var(--font-size-xs);
}

.lux-menu-item {
  padding: 0.375rem 0.5rem;
  color: var(--menu-item-color);
  font-size: var(--font-size-sm);
  border-radius: var(--small-border-radius);
  cursor: pointer;
  position: relative;
  z-index: 1;

  &.focused {
    color: var(--menu-item-hover-color);
    background-color: var(--menu-item-hover-bg-color);

    .lux-menu-icon {
      color: var(--menu-item-icon-hover-color);
    }

    .lux-menu-right-text {
      color: var(--menu-item-hover-color);
    }
  }

  .icon-text {
    min-width: 0;
  }

  .menu-icon {
    margin-right: 0.75rem;
    width: 1rem;
    height: 1rem;
    color: var(--menu-item-icon-color);
    justify-content: center;
    opacity: 1;

    :global(svg) {
      width: 1rem;
      height: 1rem;
    }
  }

  &:not(.selected):not(.has-icon) .menu-icon {
    opacity: 0;
  }

  .menu-text {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .menu-right-text {
    white-space: nowrap;
    color: var(--menu-item-secondary-color);
    margin-left: 1rem;
  }
}
