.theme-root,
.tint-root {
  --modal-header-footer-padding: 0.75rem 1.25rem;
  --modal-vertical-padding: 1rem;
  --modal-horizontal-padding: 1.25rem;
  --modal-padding: var(--modal-vertical-padding) var(--modal-horizontal-padding);

  --modal-header-bg-color: var(--elevated-secondary-bg-color);
  --modal-bg-color: var(--slight-translucent-elevated-bg-color);

  --menu-bg-color: var(--slight-translucent-elevated-bg-color);
  --menu-inverted-bg-color: var(--primary-color);
  --menu-inverted-border-color: var(--primary-divider-color);
  --menu-header-color: var(--tertiary-color-alpha);
  --menu-item-color: var(--primary-color);
  --menu-item-secondary-color: var(--tertiary-color-alpha);
  --menu-item-hover-color: var(--primary-color);
  --menu-item-hover-bg-color: var(--hover-bg-color);
  --menu-item-icon-color: var(--tertiary-color-alpha);
  --menu-item-icon-hover-color: var(--tertiary-color-alpha);
  --menu-search-bg-color: var(--slight-translucent-elevated-bg-color);
  --menu-search-divider-color: var(--divider-color);
  --menu-no-result-color: var(--tertiary-color-alpha);
  --menu-border-color: var(--divider-color);
}
