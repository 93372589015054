.lux-button-switcher {
  overflow: hidden;
  border-radius: var(--border-radius);
  background-color: var(--segment-bg-color);
  padding: var(--segment-gap);

  &.round {
    border-radius: 100px;

    .slider {
      border-radius: 100px;
    }
  }

  .segments {
    display: grid;
    grid-template-columns: repeat(var(--option-length), minmax(0, 1fr));
    position: relative;
  }

  .segment {
    padding: var(--segment-padding);
    position: relative;
    color: var(--segment-color);
    border-radius: 0;
    justify-content: center;

    &:focus-visible {
      outline: none;
    }

    &:not(.selected):focus-visible {
      color: var(--segment-hover-color);
    }

    &:hover {
      color: var(--segment-hover-color);
    }

    svg {
      margin-right: var(--segment-icon-gap);
    }

    &:not(:first-child):before {
      transition: var(--transition);
      position: absolute;
      top: 4px;
      bottom: 4px;
      left: 0;
      content: "";
      border-left: 1px solid var(--segment-divider-color);
      z-index: 0;
    }

    &.nodivider:not(:first-child):before {
      opacity: 0;
    }

    * {
      z-index: 2;
      position: relative;
    }

    &.selected {
      color: var(--segment-selected-color);
    }
  }

  .segment.selected:focus-visible {
    & + .slider {
      outline: 1px solid var(--primary-color);
    }
  }

  .slider {
    pointer-events: none;
    background-color: var(--segment-slider-bg-color);
    border-radius: calc(var(--border-radius) / 1.25);
    width: calc(100% / var(--option-length));
    box-shadow: var(--shadow-xs);
    position: absolute;
    height: 100%;
    z-index: 1;
  }

  &.always {
    padding: var(--small-segment-gap);

    .segment {
      font-size: var(--small-segment-font-size);
      padding: var(--small-segment-padding);

      svg {
        width: var(--small-segment-font-size);
        height: var(--small-segment-font-size);
      }
    }
  }

  @media (max-width: 650px) {
    &.small {
      padding: var(--small-segment-gap);

      .segment {
        font-size: var(--small-segment-font-size);
        padding: var(--small-segment-padding);

        svg {
          width: var(--small-segment-font-size);
          height: var(--small-segment-font-size);
        }
      }
    }

    &.hide-icons-small {
      .icon {
        display: none;
      }
    }
  }

  @media (max-width: 450px) {
    &.tiny {
      padding: var(--small-segment-gap);

      .segment {
        font-size: var(--small-segment-font-size);
        padding: var(--small-segment-padding);

        svg {
          width: var(--small-segment-font-size);
          height: var(--small-segment-font-size);
        }
      }
    }

    &.hide-icons-tiny {
      .icon {
        display: none;
      }
    }
  }
}
