.theme-root,
.tint-root {
  --chat-main-button-color: var(--primary-bg-color);
  --chat-main-button-bg-color: var(--primary-color);
  --chat-main-button-bg-active-color: var(--gray-80);

  --chat-conversation-heads-wrapper-box-shadow: var(--shadow-sm);

  --chat-conversation-head-bg-color: var(--primary-bg-color);
  --chat-conversation-head-bg-hover-color: var(--secondary-bg-color);
  --chat-conversation-head-bg-active-color: var(--tertiary-bg-color);
  --chat-conversation-head-active-ring-color: var(--active-border-color);

  --chat-get-support-bg-color: var(--translucent-bg-color);
  --chat-intercom-bg-color: var(--black);

  --chat-session-time-margin: 0.5rem 0.25rem 0.25rem;

  --chat-message-margin-bottom: 0.5rem;
  --chat-message-margin-bottom-same-author: 2px;
  --chat-message-padding-inner: 0.75rem;
  --chat-message-padding-outer: 2.5rem;

  --chat-message-avatar-size: 24px;
  --chat-message-avatar-margin-right: 0.5rem;

  --chat-message-bubble-padding-horizontal: 0.75rem;
  --chat-message-bubble-padding-vertical: 0.5rem;
  --chat-message-bubble-line-height: 1.4;
  --chat-message-bubble-border-radius: calc(
    (
        1rem * var(--chat-message-bubble-line-height) + 2 *
          var(--chat-message-bubble-padding-vertical)
      ) / 2
  );

  --chat-message-bubble-emoji-font-size: 3.5rem;
  --chat-message-bubble-emoji-line-height: 1.3;
  --chat-message-bubble-emoji-letter-spacing: 0.5rem;

  --chat-message-image-background: var(--modal-bg-color);

  --chat-message-read-indicator-margin-right: var(--chat-message-padding-inner);

  --chat-button-color: var(--brand-button-color, var(--white));
  --chat-window-background: var(
    --secondary-bg-color,
    var(--secondary-bg-color)
  );
}

.theme-root.dark,
.theme-root.dark .tint-root {
  --chat-main-button-bg-active-color: var(--gray-40);

  --chat-conversation-head-bg-color: var(--secondary-bg-color);
  --chat-conversation-head-bg-hover-color: var(--tertiary-bg-color);
  --chat-conversation-head-bg-active-color: var(--quaternary-bg-color);

  --chat-intercom-bg-color: var(--gray-90);
}
